// src/App.js
import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import WaitingList from './components/WaitingList';
import Consultation from './components/Consultation';
import PatientHistory from './components/PatientHistory';
import Navbar from './components/Navbar';
import DoctorSchedule from './components/DoctorSchedule';
import ConsultationList from './components/ConsultationList';
import { isTokenValid } from './utils/authUtils';


function App() {
  useEffect(() => {
    // Check token validity on app load
    if (!isTokenValid()) {
      localStorage.removeItem('token');
    }
  }, []);

  const PrivateRoute = ({ children }) => {
    return isTokenValid() ? children : <Navigate to="/login" />;
  };

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/doctor-schedule" element={<PrivateRoute><DoctorSchedule /></PrivateRoute>} />
          <Route path="/waiting-list" element={<PrivateRoute><WaitingList /></PrivateRoute>} />
          <Route path="/consultation/:patientId" element={<PrivateRoute><Consultation /></PrivateRoute>} />
          <Route path="/patient-history/:patientId" element={<PrivateRoute><PatientHistory /></PrivateRoute>} />
          <Route path="/consultation-list" element={<PrivateRoute><ConsultationList /></PrivateRoute>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;