import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Typography, TextField, Button, CircularProgress, Paper, List, ListItem, ListItemText, Divider, IconButton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { connect, createLocalVideoTrack } from 'twilio-video';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DescriptionIcon from '@mui/icons-material/Description';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { getBaseUrl } from '../utils/apiUtil';



function Consultation() {
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');
  const [roomName, setRoomName] = useState(null);
  const [chat, setChat] = useState([]);
  const [room, setRoom] = useState(null);
  const [loading, setLoading] = useState(true);

  const [participants, setParticipants] = useState([]);

  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [remoteAudioTrack, setRemoteAudioTrack] = useState(null);
  const [isAudioMuted, setIsAudioMuted] = useState(false);

  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();

  const [localTrack, setLocalTrack] = useState(null);
  const [remoteTrack, setRemoteTrack] = useState(null);

  const [consultationData, setConsultationData] = useState(null);

  const { patientId } = useParams();

  const [medicalHistory, setMedicalHistory] = useState([]);
  const [newEntry, setNewEntry] = useState({
    diagnosis: '',
    treatment: '',
    notes: ''
  });
  const [attachments, setAttachments] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const fileInputRef = useRef();

  const navigate = useNavigate();

  const getFileIcon = (fileType) => {
    if (!fileType) return <InsertDriveFileIcon />;
    if (fileType.startsWith('image')) return <ImageIcon />;
    if (fileType === 'application/pdf') return <PictureAsPdfIcon />;
    if (fileType.startsWith('video')) return <VideoLibraryIcon />;
    if (fileType === 'text/plain') return <DescriptionIcon />;
    return <InsertDriveFileIcon />;
  };
  console.log("Patient ID " + patientId);

  const getDisplayName = (attachment) => {
    if (attachment.filename) {
      return attachment.filename.length > 10
        ? attachment.filename.substring(0, 7) + '...'
        : attachment.filename;
    }
    // Fallback: use the last part of the URL as the filename
    const urlParts = attachment.url.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    return lastPart.length > 10 ? lastPart.substring(0, 7) + '...' : lastPart;
  };

  useEffect(() => {

    fetchConsultationData()
      .then(fetchedRoomName => {
        if (fetchedRoomName) {
          return axios.post(`${getBaseUrl()}/api/video/token`, { roomName: fetchedRoomName }, { headers: { 'x-auth-token': localStorage.getItem('token') } });
        } else {
          throw new Error('No room name fetched');
        }
      })
      .then(res => {
        setToken(res.data.token);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching consultation data or Twilio token:', error);
        setLoading(false);
      });

      axios.get(`${getBaseUrl()}/api/medical-history/${patientId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      })
        .then(res => setMedicalHistory(res.data.records))
        .catch(error => console.error('Error fetching medical history:', error));

      return () => {
        if (room) {
          room.disconnect();
        }
      };

  }, [patientId]);

  const fetchConsultationData = async () => {
    try {
      const res = await axios.get(`${getBaseUrl()}/api/consultations/consultation-data/${patientId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setConsultationData(res.data);
      setRoomName(res.data.activeConsultation.roomName);
      console.log("Consultation Data");
      console.log(res.data);
      return res.data.activeConsultation.roomName;
    } catch (error) {
      console.error('Error fetching consultation data:', error);
    }
  };

  useEffect(() => {
    let mounted = true;

    const startVideoCall = async (token, roomName) => {
      try {
        const videoRoom = await connect(token, {
          name: roomName,
          video: { width: 1024, height: 1080, frameRate: 30 },
          preferredVideoCodecs: ['VP8', 'H264'],
          networkQuality: { local: 1, remote: 1 },
          audio: true
        });

        setRoom(videoRoom);

        videoRoom.localParticipant.audioTracks.forEach(publication => {
          if (publication.track) {
            setLocalAudioTrack(publication.track);
          }
        });

        // Attach local video
        const track = await createLocalVideoTrack({
          width: 640,
          height: 480,
          frameRate: 24
        });
        if (mounted) {
          setLocalTrack(track);
        }

        // Handle remote participants
        const handleParticipantConnected = (participant) => {
          setParticipants(prevParticipants => [...prevParticipants, participant]);
          handleTrackPublication(participant);
        };

        const handleTrackPublication = (participant) => {
          participant.tracks.forEach(publication => {
            if (publication.track) {
              handleTrackSubscribed(publication.track, participant);
            }
            publication.on('subscribed', track => handleTrackSubscribed(track, participant));
          });
        };

        const handleTrackSubscribed = (track, participant) => {
          if (track.kind === 'video' && mounted) {
            setRemoteTrack(track);
          } else if ( track.kind === 'audio' && mounted ) {
            setRemoteAudioTrack(track);
          }
        };

        const handleParticipantDisconnected = (participant) => {
          setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
          if (mounted) {
            setRemoteTrack(null);
          }
        };

        videoRoom.on('participantConnected', handleParticipantConnected);
        videoRoom.on('participantDisconnected', handleParticipantDisconnected);
        videoRoom.participants.forEach(handleParticipantConnected);

      } catch (error) {
        console.error('Error starting video call', error);
      }
    };

    if (token && roomName) {
      startVideoCall(token, roomName);
    }

    return () => {
      mounted = false;
      if (localTrack) {
        localTrack.stop();
      }
      if (room) {
        room.disconnect();
      }
    };
  }, [token, roomName]);

  useEffect(() => {
    if (localTrack && localVideoRef.current) {
      const videoElement = localTrack.attach();
      videoElement.style.width = '100%';
      videoElement.style.height = '100%';
      videoElement.style.objectFit = 'cover';
      localVideoRef.current.innerHTML = '';
      localVideoRef.current.appendChild(videoElement);
    }
    return () => {
      if (localTrack) {
        localTrack.detach();
      }
    };
  }, [localTrack]);

  useEffect(() => {
    if (remoteTrack && remoteVideoRef.current) {
      const videoElement = remoteTrack.attach();
      videoElement.style.width = '100%';
      videoElement.style.height = '100%';
      videoElement.style.objectFit = 'contain';
      remoteVideoRef.current.innerHTML = '';
      remoteVideoRef.current.appendChild(videoElement);
    }
    return () => {
      if (remoteTrack) {
        remoteTrack.detach();
      }
    };
  }, [remoteTrack]);

  useEffect(() => {
    if (remoteAudioTrack && remoteAudioRef.current) {
      const audioElement = remoteAudioTrack.attach();
      remoteAudioRef.current.innerHTML = '';
      remoteAudioRef.current.appendChild(audioElement);
    }
    return () => {
      if (remoteAudioTrack) {
        remoteAudioTrack.detach();
      }
    };
  }, [remoteAudioTrack]);

  const handleNewEntryChange = (e) => {
    setNewEntry({ ...newEntry, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    setAttachments([...attachments, ...event.target.files]);
  };

  const addMedicalHistoryEntry = async () => {
    try {
      const formData = new FormData();
      formData.append('patientId', patientId);
      formData.append('date', new Date().toISOString());
      formData.append('diagnosis', newEntry.diagnosis);
      formData.append('treatment', newEntry.treatment);
      formData.append('notes', newEntry.notes);

      attachments.forEach((file) => {
        formData.append('attachments', file);
      });

      const res = await axios.post(`${getBaseUrl()}/api/medical-history/add`, formData, {
        headers: { 
          'x-auth-token': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      });

      // After successful upload, refetch the entire medical history
      const updatedHistory = await axios.get(`${getBaseUrl()}/api/medical-history/${patientId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setMedicalHistory(updatedHistory.data.records);

      setNewEntry({ diagnosis: '', treatment: '', notes: '' });
      setAttachments([]);
    } catch (error) {
      console.error('Failed to add medical history entry', error);
    }
  };

  const viewAttachment = (attachment) => {
    const fullUrl = `${getBaseUrl()}${attachment.url}`;
    setSelectedAttachment({ ...attachment, url: fullUrl });
  };

  const endConsultation = async () => {
    try {
      await axios.post(`${getBaseUrl()}/api/consultations/end-consultation`, 
        { patientId, appointmentId: consultationData.appointmentId },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      
      // Disconnect from the video room
      if (room) {
        room.disconnect();
      }
      
      // Navigate back to the consultation list
      navigate('/consultation-list');
    } catch (error) {
      console.error('Failed to end consultation', error);
    }
  };
  

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="h5" gutterBottom>Consultation with Patient</Typography>
        <Button 
            variant="contained" 
            color="secondary" 
            startIcon={<CloseIcon />} 
            onClick={endConsultation}
          >
            End Consultation
          </Button>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div style={{ display: 'flex', height: 'calc(100vh - 100px)' }}>
          <div style={{ flex: 3, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
            <Paper 
              elevation={3} 
              style={{ 
                flex: 1, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                backgroundColor: '#f0f0f0',
                marginBottom: '20px',
                position: 'relative',
                overflow: 'hidden'
              }}
            >
              <div 
                ref={remoteVideoRef} 
                style={{ 
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              >
                {participants.length === 0 && <Typography>Waiting for patient to join...</Typography>}
              </div>
            </Paper>
            <Paper 
              elevation={3} 
              style={{
                width: '240px',
                height: '180px',
                overflow: 'hidden',
                borderRadius: '8px',
                backgroundColor: '#000'
              }}
            >
              <audio ref={remoteAudioRef} autoPlay />
              <div 
                ref={localVideoRef} 
                style={{
                  width: '100%',
                  height: '100%',
                }}
              ></div>
            </Paper>
          </div>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '20px', maxWidth: '400px' }}>
                <Paper elevation={3} style={{ flex: 2, display: 'flex', flexDirection: 'column', padding: '20px', overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>Medical History</Typography>
            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>Add New Entry</Typography>
              <TextField
                label="Diagnosis"
                name="diagnosis"
                value={newEntry.diagnosis}
                onChange={handleNewEntryChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Treatment"
                name="treatment"
                value={newEntry.treatment}
                onChange={handleNewEntryChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Notes"
                name="notes"
                value={newEntry.notes}
                onChange={handleNewEntryChange}
                fullWidth
                margin="normal"
                variant="outlined"
                multiline
                rows={3}
              />
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <Button
                startIcon={<AttachFileIcon />}
                onClick={() => fileInputRef.current.click()}
                variant="outlined"
                color="primary"
                style={{ marginTop: '10px' }}
              >
                Attach Files
              </Button>
              {attachments.length > 0 && (
                <Typography variant="body2" style={{ marginTop: '10px' }}>
                  {attachments.length} file(s) selected
                </Typography>
              )}
              <Button onClick={addMedicalHistoryEntry} variant="contained" color="primary" fullWidth style={{ marginTop: '10px' }}>
                Add Entry
              </Button>
            <List>
              {medicalHistory.map((record, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={new Date(record.date).toLocaleDateString()}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="textPrimary">
                            Diagnosis: {record.diagnosis}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="textPrimary">
                            Treatment: {record.treatment}
                          </Typography>
                          {record.notes && (
                            <>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                Notes: {record.notes}
                              </Typography>
                            </>
                          )}
                          {record.attachments && record.attachments.length > 0 && (
                            <>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                Attachments:
                              </Typography>
                              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '8px' }}>
                                {record.attachments.map((attachment, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                      padding: '4px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                      backgroundColor: '#f0f0f0'
                                    }}
                                    onClick={() => viewAttachment(attachment)}
                                  >
                                    {getFileIcon(attachment.fileType)}
                                    <Typography variant="caption" style={{ marginLeft: '4px' }}>
                                      {attachment.filename.length > 10
                                        ? attachment.filename.substring(0, 7) + '...'
                                        : attachment.filename}
                                    </Typography>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                  {index < medicalHistory.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
            </Paper>
          </div>
        </div>
      )},
      {selectedAttachment && (
  <Modal
    open={!!selectedAttachment}
    onClose={() => setSelectedAttachment(null)}
    aria-labelledby="attachment-modal-title"
    aria-describedby="attachment-modal-description"
  >
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '800px',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    }}>
      <Typography id="attachment-modal-title" variant="h6" component="h2">
        {selectedAttachment.filename}
      </Typography>
      {selectedAttachment.fileType.startsWith('image') ? (
        <img src={selectedAttachment.url} alt={selectedAttachment.filename} style={{ maxWidth: '100%', maxHeight: '80vh' }} />
      ) : selectedAttachment.fileType === 'application/pdf' ? (
        <iframe src={selectedAttachment.url} width="100%" height="600px" title={selectedAttachment.filename} />
      ) : (
        <Typography id="attachment-modal-description">
          This file type cannot be displayed in the browser. <a href={selectedAttachment.url} target="_blank" rel="noopener noreferrer">Click here to download.</a>
        </Typography>
      )}
    </Box>
  </Modal>
)}
    </div>
  );
}

export default Consultation;