// src/components/WaitingList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemText, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getBaseUrl } from '../utils/apiUtil';


function WaitingList() {
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchWaitingList();
  }, []);

  const fetchWaitingList = async () => {
    try {
      const res = await axios.get(`${getBaseUrl()}/api/waiting-room/list`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setPatients(res.data);
    } catch (error) {
      console.error('Failed to fetch waiting list', error);
    }
  };

  const startConsultation = (patientId) => {
    navigate(`/consultation/${patientId}`);
  };

  const viewPatientHistory = (patientId) => {
    navigate(`/patient-history/${patientId}`);
  };

  return (
    <div>
      <Typography variant="h5">Waiting List</Typography>
      {patients.length > 0 ? (
        <List>
          {patients.map((patient) => (
            <ListItem key={patient._id}>
              <ListItemText primary={patient.patient.name} />
              <Button onClick={() => startConsultation(patient.patient._id)}>Start Consultation</Button>
              <Button onClick={() => viewPatientHistory(patient.patient._id)}>View History</Button>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="subtitle1">No patients in the waiting list.</Typography>
      )}
    </div>
  );
}

export default WaitingList;