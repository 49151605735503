import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemText, Button, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getBaseUrl } from '../utils/apiUtil';


function ConsultationList() {
  const [consultationList, setConsultationList] = useState({ waitingRoom: [], scheduledAppointments: [] });
  const navigate = useNavigate();

  useEffect(() => {
    fetchConsultationList();
  }, []);

  const fetchConsultationList = async () => {
    try {
      const res = await axios.get(`${getBaseUrl()}/api/consultations/consultation-list`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setConsultationList(res.data);
    } catch (error) {
      console.error('Failed to fetch consultation list', error);
    }
  };

  const startConsultation = async (patientId, appointmentId = null) => {
    try {
      const res = await axios.post(`${getBaseUrl()}/api/consultations/start-consultation`, 
        { patientId, appointmentId },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      navigate(`/consultation/${patientId}`);
    } catch (error) {
      console.error('Failed to start consultation', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <div>
      <Typography variant="h5">Consultation List</Typography>
      <Typography variant="h6">Waiting Room</Typography>
      <List>
        {consultationList.waitingRoom.map((patientId) => (
          <ListItem key={patientId}>
            <ListItemText primary={`Patient ID: ${patientId}`} />
            <Button onClick={() => startConsultation(patientId)}>Start Consultation</Button>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant="h6">Upcoming Appointments</Typography>
      <List>
        {consultationList.scheduledAppointments.map((appointment) => (
          <ListItem key={appointment._id}>
            <ListItemText 
              primary={appointment.patientId ? appointment.patientId.name : 'Unknown Patient'} 
              secondary={appointment.date ? new Date(appointment.date).toLocaleDateString('en-GB') : 'No date'} 
            />
            <ListItemText 
              primary={appointment.startTime ? appointment.startTime : 'No time'} 
            />
            <Button onClick={() => startConsultation(appointment.patientId._id, appointment._id)}>
              Start Consultation
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default ConsultationList;