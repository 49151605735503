// src/components/PatientHistory.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { getBaseUrl } from '../utils/apiUtil';


function PatientHistory() {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const { patientId } = useParams();

  useEffect(() => {
    fetchPatientHistory();
  }, [patientId]);

  const fetchPatientHistory = async () => {
    try {
      const res = await axios.get(`${getBaseUrl()}/api/medical-history/${patientId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setHistory(res.data.records);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch patient history', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <Typography>Loading patient history...</Typography>;
  }

  return (
    <div>
      <Typography variant="h5">Patient Medical History</Typography>
      <List>
        {history.map((record, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText
                primary={new Date(record.date).toLocaleDateString()}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textPrimary">
                      Diagnosis: {record.diagnosis}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2" color="textPrimary">
                      Treatment: {record.treatment}
                    </Typography>
                    {record.notes && (
                      <>
                        <br />
                        <Typography component="span" variant="body2" color="textSecondary">
                          Notes: {record.notes}
                        </Typography>
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

export default PatientHistory;