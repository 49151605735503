import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Grid, Paper, Checkbox, FormGroup, FormControlLabel, Select, MenuItem, InputLabel, FormControl, Alert, Snackbar } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, addMinutes, areIntervalsOverlapping, isEqual, parse } from 'date-fns';
import axios from 'axios';
import { getBaseUrl } from '../utils/apiUtil';


const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const DURATIONS = [15, 30, 45, 60];

const DoctorSchedule = () => {
  const [schedule, setSchedule] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [startTime, setStartTime] = useState(new Date().setHours(9, 0, 0, 0));
  const [endTime, setEndTime] = useState(new Date().setHours(17, 0, 0, 0));
  const [duration, setDuration] = useState(30);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    fetchSchedule();
  }, []);

  const fetchSchedule = async () => {
    try {
      const response = await axios.get(`${getBaseUrl()}/api/doctors/schedule`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      const formattedSchedule = {};
      response.data.timeSlots.forEach(slot => {
        if (!formattedSchedule[slot.day]) {
          formattedSchedule[slot.day] = [];
        }
        formattedSchedule[slot.day].push({
          startTime: parse(slot.startTime, 'HH:mm', new Date()),
          endTime: parse(slot.endTime, 'HH:mm', new Date())
        });
      });
      setSchedule(formattedSchedule);
    } catch (error) {
      setError('Failed to fetch schedule');
    }
  };

  const handleDayChange = (day) => {
    setSelectedDays(prev => 
      prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
    );
  };

  const isOverlapping = (newSlot, existingSlots) => {
    return existingSlots.some(slot => 
      areIntervalsOverlapping(
        { start: newSlot.startTime, end: newSlot.endTime },
        { start: slot.startTime, end: slot.endTime }
      ) || isEqual(newSlot.startTime, slot.startTime) || isEqual(newSlot.endTime, slot.endTime)
    );
  };

  const handleAddTimeBlock = () => {
    if (startTime >= endTime) {
      setNotification({ open: true, message: 'End time must be after start time', severity: 'error' });
      return;
    }

    const newSchedule = { ...schedule };
    let hasOverlap = false;
    let overlapDay = '';

    selectedDays.forEach(day => {
      if (!newSchedule[day]) newSchedule[day] = [];
      const newSlots = [];
      let currentTime = new Date(startTime);
      
      while (currentTime < new Date(endTime)) {
        const slotEndTime = new Date(Math.min(addMinutes(currentTime, duration).getTime(), endTime));
        const newSlot = { startTime: currentTime, endTime: slotEndTime };
        
        if (isOverlapping(newSlot, newSchedule[day])) {
          hasOverlap = true;
          overlapDay = day;
          break;
        }
        
        newSlots.push(newSlot);
        currentTime = new Date(slotEndTime);
      }

      if (!hasOverlap) {
        newSchedule[day] = [...newSchedule[day], ...newSlots];
        newSchedule[day].sort((a, b) => a.startTime - b.startTime);
      }
    });

    if (hasOverlap) {
      setNotification({ open: true, message: `Time block overlaps with existing slots on ${overlapDay}`, severity: 'error' });
    } else {
      setSchedule(newSchedule);
      setSelectedDays([]);
      setNotification({ open: true, message: 'Time block added successfully', severity: 'success' });
    }
  };

  const handleRemoveTimeBlock = (day, index) => {
    setSchedule(prev => ({
      ...prev,
      [day]: prev[day].filter((_, i) => i !== index)
    }));
    setNotification({ open: true, message: 'Time slot removed', severity: 'info' });
  };

  const handleSubmit = async () => {
    try {
      const timeSlots = Object.entries(schedule).flatMap(([day, slots]) =>
        slots.map(slot => ({
          day,
          startTime: format(slot.startTime, 'HH:mm'),
          endTime: format(slot.endTime, 'HH:mm')
        }))
      );

      await axios.post(`${getBaseUrl()}/api/doctors/schedule`, { timeSlots }, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });

      setError('');
      setNotification({ open: true, message: 'Schedule updated successfully!', severity: 'success' });
    } catch (error) {
      setError('Failed to update schedule');
      setNotification({ open: true, message: 'Failed to update schedule', severity: 'error' });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
        <Typography variant="h4" gutterBottom>My Weekly Schedule</Typography>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>Add Time Block</Typography>
          <FormGroup row>
            {WEEKDAYS.map(day => (
              <FormControlLabel
                key={day}
                control={<Checkbox checked={selectedDays.includes(day)} onChange={() => handleDayChange(day)} />}
                label={day}
              />
            ))}
          </FormGroup>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={4}>
              <TimePicker
                label="Start Time"
                value={new Date(startTime)}
                onChange={(newValue) => setStartTime(newValue?.getTime() || startTime)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TimePicker
                label="End Time"
                value={new Date(endTime)}
                onChange={(newValue) => setEndTime(newValue?.getTime() || endTime)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Duration (minutes)</InputLabel>
                <Select
                  value={duration}
                  label="Duration (minutes)"
                  onChange={(e) => setDuration(e.target.value)}
                >
                  {DURATIONS.map(d => (
                    <MenuItem key={d} value={d}>{d}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleAddTimeBlock} sx={{ mt: 2 }}>
            Add Time Block
          </Button>
        </Paper>
        {WEEKDAYS.map(day => (
          <Paper key={day} elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6">{day}</Typography>
            {schedule[day]?.map((slot, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography>
                  {format(slot.startTime, 'HH:mm')} - {format(slot.endTime, 'HH:mm')}
                </Typography>
                <Button variant="outlined" color="error" onClick={() => handleRemoveTimeBlock(day, index)}>
                  Remove
                </Button>
              </Box>
            ))}
          </Paper>
        ))}
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 3 }}
        >
          Save Schedule
        </Button>
        <Snackbar 
          open={notification.open} 
          autoHideDuration={6000} 
          onClose={() => setNotification({ ...notification, open: false })}
        >
          <Alert onClose={() => setNotification({ ...notification, open: false })} severity={notification.severity} sx={{ width: '100%' }}>
            {notification.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default DoctorSchedule;